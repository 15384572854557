import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { EnvConfig } from '../config/env-config';
import { RecaptchaService } from './services/recaptcha.service';
import { RecaptchaComponent } from 'ng-recaptcha';
import { appConstants } from './config/app-constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  recaptchaKey = EnvConfig.RECAPTCHA_KEY;
  automation = false;

  @ViewChild('captchaRef', { static: false }) recaptchaRef: RecaptchaComponent;

  constructor(private recaptchaService: RecaptchaService) {}

  ngOnInit(): void {
    // not using activated route for query param since app component is outside of router outlet
    this.automation = window.location.href.indexOf('source=' + appConstants.testHarness) >= 0 && document.cookie.indexOf('hartfordEmployee=1') >= 0;
  }

  ngAfterViewInit(): void {
    if (this.recaptchaRef && !this.automation) {
      // This will generate the recaptcha token and send it to resolve function
      this.recaptchaService.setRecaptchaComponent(this.recaptchaRef);
    } else if (this.automation) {
      this.recaptchaService.setRecaptchaToken(appConstants.testHarness);
    }
  }

  recaptchaResolved(token: string): void {
    this.recaptchaService.setRecaptchaToken(token);
  }

  recaptchaError(error: any) {
    if (error) {
      console.error('reCAPTCHA error encountered');
    }
  }
}

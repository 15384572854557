<div id="Search_Input_Section" class="card py-7 mx-3 my-3">
  <div class="card-body">
    <div class="row text-center">
      <h1>
        Where Can I Find Local Agents?
      </h1>
    </div>
    <div class="form" (ngSubmit)="submitLocationDetails($event)">
      <div class="row">
        <div class="col-xs-12 col-md-4 col-md-offset-2 location-input">
          <huk-input-field aligntext="left"
                           ngDefaultControl
                           [(ngModel)]="location"
                           name="address"
                           (keyup)="handleKeyUp($event); analytics.trackHuk2InputField('Agency Location', $event)"
                           labelformat="text" maxlength="80"
                           placeholder="Search by city and state, or ZIP"
                           lefticonclass="location"
                           hukid="agency_location_input"
          >
          </huk-input-field>
        </div>
        <div class="col-xs-12 col-md-2 radius-select">
          <huk-select dropposition="bottom-left" labelformat="text"
                      ngDefaultControl
                      [(ngModel)]="radius"
                      options='[{"name":"25 miles","value":"25"},
                            {"name":"50 miles","value":"50"},
                            {"name":"75 miles","value":"75"},
                            {"name":"100 miles","value":"100"},
                            {"name":"200 miles","value":"200"}]'
                      size="medium"
                      name="distance"
                      hukid="agency_radius_select"
                      (hukChange)="analytics.trackHuk2Select('Agency Radius', $event)">>
          </huk-select>
        </div>
        <div class="col-xs-12 col-md-2 search-button">
          <huk-button hukclass="btn-primary btn-sm" iconclass="search" text="Search" type="button"
                      hukid="agency_search_button"
                      (hukClick)="onSearchLocation(); analytics.trackHuk2Button('Agency Search', $event)">
          </huk-button>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="Search_Results_Loading" *ngIf="showSpinner" class="text-center">
  <span *ngIf="showSpinner" class="icon icon-spinner icon-pulse"></span>
</div>

<div *ngIf="!showSpinner" class="agency-text">
  <app-error-message
    [serviceUnavailable]="isServicesUnavailable"
    [invalidZip]="invalidZip"
    [invalidStateOrCity]="invalidStateOrCity"
    [errorMsgText]="errorMsgText"
    [noResults]="noResults"
    [noResultsInRadius]="noResultsInRadius"
    [invalidSearch]="invalidSearch">
  </app-error-message>
  <div id="Search_Results_Section" *ngIf="locationList.length">
    <h3 class="text-center">We found {{locationList.length}} agencies within
      {{displayRadius}} miles of you</h3>
    <div class="col-md-8 col-md-offset-2">
      <p class="text-center info-paragraph mb-4">Search results may only be a partial list of our agencies who
        can
        help you with your insurance needs.</p>
    </div>
  </div>
</div>

<div *ngIf="locationList.length" class="row displayed-results">
  <div *ngFor="let location of paginatedLocationList"
       class="location-results col-xs-12 col-md-8 col-md-offset-2 mb-3 g-0">
    <huk-card
      leftheading="<div style='margin-top: 0.35em; font-weight: bold'>{{location.name}}</div>"
      leftheadingformat="html"
      rightheading="<div class='h5' style='text-align: right; margin-left: 5px'>{{location.distance}} miles away</div>"
      rightheadingformat="html">
      <div class="row mt-2 agency-address-info">
        <div class="col-md-6"><p>{{location.address.street}} <br> {{location.address.city}},
          {{location.address.state}} {{location.address.zip}}</p>
        </div>
        <div class="col-md-6 agency-aarp" *ngIf="lob === 'PL' && location.properties.aarpInd === 'Y'">
          <img *ngIf="location.address.state !== 'FL'"
               src="../../assets/images/AARPHorizontal.svg"
               class="pull-right">
          <img *ngIf="location.address.state === 'FL'"
               src="../../assets/images/AARPHorizontal_FL.jpg"
               class="pull-right">
        </div>
      </div>
      <div class="px-1">
        <hr>
      </div>
      <div class="row pb-2 agency-details">
        <a *ngIf="location.properties.phoneNumber" class="pr-3 detail-link" target="_blank"
           href="tel:{{location.properties.phoneNumber | phoneNumber}}"
           (click)="trackLink('PhoneNumber', location)">
          <i class="icon icon-call"></i> {{location.properties.phoneNumber | phoneNumber}}
        </a>
        <a [href]="location | directionsLink" class="pr-3 detail-link" target="_blank"
           (click)="trackLink('Directions', location)"><i
          class="icon icon-location"></i>
          Directions</a>
        <a *ngIf="location.properties.url" [href]="location.properties.url | websiteUrl"
           class="pr-3 detail-link" target="_blank"
           (click)="trackLink('Website', location)">
          <i class="icon icon-link"></i> Website
        </a>
      </div>
    </huk-card>
  </div>
  <div class="row" *ngIf="!noResults">
    <div class="col-xs-12">
      <huk-paginator pages="{{numberOfPages}}" pagesdisplayed="{{tilesDisplayed}}"
                     (hukClick)="changePages($event); analytics.trackHuk2Paginator('Agency Pagination', $event)">
      </huk-paginator>
    </div>
  </div>
</div>

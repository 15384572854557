import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent {
  @Input() invalidZip = false;
  @Input() serviceUnavailable = false;
  @Input() invalidStateOrCity = false;
  @Input() errorMsgText = '';
  @Input() noResults = false;
  @Input() noResultsInRadius = false;
  @Input() invalidSearch = false;
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
  maxLength = 11;

  transform(value: string | null | undefined, includeCountry = false): string {
    if (!value) {
      return '';
    }
    let country = 1;
    let city;
    let number;
    if (value) {
      value = value.replace(/[^0-9]/g, '').slice(0, this.maxLength);
      switch (value.length) {
        case 7: // 8675309
          city = value.slice(0, 3);
          number = value.slice(3);
          return `${city}-${number}`;
          break;
        case 10: // 8008675309
          city = value.slice(0, 3);
          number = value.slice(3);
          number = number.slice(0, 3) + '-' + number.slice(3);
          return includeCountry ? `${country} (${city}) ${number}` : `(${city}) ${number}`;
          break;
        case 11: // 18008675309
          country = parseInt(value[0], 10);
          city = value.slice(1, 4);
          number = value.slice(4);
          number = number.slice(0, 3) + '-' + number.slice(3);
          return `${country} (${city}) ${number}`;
          break;
        default:
          return value;
      }
    }
    return value;
  }
}

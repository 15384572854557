import { EnvConfig } from '../../config/env-config';
import { Location } from '../services/location.service';
import { TealiumEventData } from '../analytics/analytics.service';

export class IframeUtils {
  public static resizeIframe(pageChange = false): void {
    if (EnvConfig.PARENT_DOMAIN && window.parent && window !== window.parent) {
      // parent expects event like loc:iframeHeightUpdated//{height}
      // in setTimeout to give the dom a second to render the tiles
      setTimeout(() => {
        const elem = document.getElementById('LocatorApp_BottomOfPage');
        if (elem) {
          // extra height added to elem.offsetTop to accommodate for smaller devices - more height allows for iframe to have full length on page
          window.parent.postMessage(`loc:iframeHeightUpdated//${elem.offsetTop + 50}`, EnvConfig.PARENT_DOMAIN);
          if (pageChange) {
            const resultsSection = document.getElementById('Search_Results_Section');
            if (resultsSection) {
              // scroll to top on page change
              window.parent.postMessage(`loc:scrollPage//${resultsSection.offsetTop}`, EnvConfig.PARENT_DOMAIN);
            }
          }
        }
      }, 600);
    }
  }

  public static emitShopLocation(location: Location, unselectShop = false): void {
    if (EnvConfig.PARENT_DOMAIN && window.parent && window !== window.parent) {
      const phoneNumber = location.properties.phoneNumber || null;
      const county = location.address.county || null;
      const messageKey = !unselectShop ? 'loc:selectedShop' : 'loc:unselectedShop';
      if (location.address.state === 'MA' && location.properties.outOfNetwork === 'Y') {
        // Non-CRSP
        //mapping: {messageKey//publicId//street, city, state, zip, phoneNumber, repairShop Name, indicator for Non-CSRP, county, repairShop Number}
        //loc:selectedShop//RS0002317//1363 Main Street, Agawam, MA, 01001, 8605677880, ACE AUTOBODY, False, Hampden, RS0002317';
        window.parent.postMessage(
          `${messageKey}//${location.properties.shopNumber}//${location.address.street}, ${location.address.city}, ${location.address.state}, ${location.address.zip}, ${phoneNumber}, ${location.name}, False, ${county}, ${location.properties.shopNumber}`,
          EnvConfig.PARENT_DOMAIN
        );
      } else {
        //CRSP
        //mapping: {messageKey//publicId//street, city, state, zip, phoneNumber, repairShop Name, indicators for CRSP, county - Not Applicable for CRSP, repairShop Number - Not Applicable for CRSP}
        //loc:selectedShop//11926//One Hartford Plaza, Hartford, CT, 06105, 8602516859, CT CRUMBLING FOUNDATION ECL, True, not listed, not listed
        window.parent.postMessage(
          `${messageKey}//${location.properties.htCode}//${location.address.street}, ${location.address.city}, ${location.address.state}, ${location.address.zip}, ${phoneNumber}, ${location.name}, True, not listed, not listed`,
          EnvConfig.PARENT_DOMAIN
        );
      }
    }
  }

  public static emitAnalyticsEvent(data: TealiumEventData) {
    // Tealium listens to this event and call the _trackAnalytics(data) in parent window
    //loc:analyticsEvent//{"event_parent":"Page Tag", "event_type":"Select Click", "event_id":"Agency Radius", "event_value":"75","data_track":"true"}
    if (EnvConfig.PARENT_DOMAIN && window.parent && window !== window.parent) {
      window.parent.postMessage(`loc:analyticsEvent//${JSON.stringify(data)}`, EnvConfig.PARENT_DOMAIN);
    }
  }
}

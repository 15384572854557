import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AgencyComponent } from './agency/agency.component';
import { RepairShopComponent } from './repair-shop/repair-shop.component';
import { PhoneNumberPipe } from './utilities/phone-number.pipe';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { GeoLocationService } from './services/geo-location.service';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaService } from './services/recaptcha.service';
import { LocationService } from './services/location.service';
import { AnalyticsService } from './analytics/analytics.service';
import { DirectionsLinkPipe } from './utilities/directions-link.pipe';
import { WebsiteUrlPipe } from './utilities/website-url.pipe';
import { ErrorMessageComponent } from './shared/error-message/error-message.component';

@NgModule({
  declarations: [AppComponent, AgencyComponent, RepairShopComponent, PhoneNumberPipe, DirectionsLinkPipe, WebsiteUrlPipe, ErrorMessageComponent],
  imports: [BrowserModule, FormsModule, AppRoutingModule, HttpClientModule, RecaptchaModule],
  providers: [GeoLocationService, RecaptchaService, LocationService, AnalyticsService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'websiteUrl',
})
export class WebsiteUrlPipe implements PipeTransform {
  transform(value: string | null | undefined): string {
    if (!value) {
      return '';
    }
    let url = value.toLowerCase();
    if (!url.startsWith('http')) {
      url = 'http://' + url;
    }
    return url;
  }
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AgencyComponent } from './agency/agency.component';
import { RepairShopComponent } from './repair-shop/repair-shop.component';
import { appConstants } from './config/app-constants';

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: appConstants.agencyURL,
        component: AgencyComponent,
        data: { 'title': appConstants.agencyTitle },
      },
      {
        path: appConstants.repairShopURL,
        component: RepairShopComponent,
        data: { 'title': appConstants.repairShopTitle },
      },
    ]),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export class EnvConfig {
  // This variable types the env config to prevent missing property compile time errors
  public static PRODUCTION: boolean;
  public static RECAPTCHA_KEY: string;
  public static API_BASE: string;
  public static TARGET_ENV: string;
  // set the parent domain based on application url that is being accessed
  public static PARENT_DOMAIN: string;

  static initCheck(): void {
    const location: string = this.getHostname().toLowerCase();
    this.PRODUCTION = false;
    if (location.indexOf('localhost') >= 0) {
      // local
      this.RECAPTCHA_KEY = '6LcoJUQhAAAAAH1K8Fe36sO-OMHkxfbjQ_a3-nto';
      this.API_BASE = 'http://localhost.thehartford.com:3003/';
      this.TARGET_ENV = 'int';
    } else if (location.startsWith('https://int-')) {
      // INT
      this.RECAPTCHA_KEY = '6LcoJUQhAAAAAH1K8Fe36sO-OMHkxfbjQ_a3-nto';
      this.API_BASE = 'https://int-api.thehartford.com/';
      this.TARGET_ENV = 'int';
      // locator int url is being referenced from both dev, int urls of hig.com and dev url of pl auto claims
      this.PARENT_DOMAIN = this.getParentDomain();
    } else if (location.startsWith('https://qa-')) {
      // QA
      this.RECAPTCHA_KEY = '6LcoJUQhAAAAAH1K8Fe36sO-OMHkxfbjQ_a3-nto';
      this.API_BASE = 'https://qa-api.thehartford.com/';
      this.TARGET_ENV = 'qa';
      // locator qa url is being referenced from qa url of pl auto claims
      this.PARENT_DOMAIN = this.getParentDomain();
    } else if (location.startsWith('https://pte-')) {
      // PTE
      this.RECAPTCHA_KEY = '6LcoJUQhAAAAAH1K8Fe36sO-OMHkxfbjQ_a3-nto';
      this.API_BASE = 'https://qa-api.thehartford.com/';
      this.TARGET_ENV = 'pte';
      // locator pte url is being referenced from pte url of pl auto claims
      this.PARENT_DOMAIN = this.getParentDomain();
    } else {
      // PROD
      this.PRODUCTION = true;
      this.RECAPTCHA_KEY = '6LcDKEQhAAAAADBt4lDG_PmSPDXR5Ks2oT9TPjck';
      this.API_BASE = 'https://api.thehartford.com/';
      this.TARGET_ENV = '';
      // locator prod url is being referenced from both qa, prod urls of hig.com and prod url of pl auto claims
      this.PARENT_DOMAIN = this.getParentDomain();
    }
  }

  // Location helper to avoid window stubbing nonsense
  static getHostname() {
    return window.location.href;
  }

  static getParentDomain() {
    const PARENT_URL = ((window.location !== window.parent.location ? document.referrer : document.location) || '').toString();
    return new URL(PARENT_URL).origin;
  }
}

EnvConfig.initCheck();

<!-- error message when service is unavailable -->
<div class="error_wrapper text-center" *ngIf="serviceUnavailable">
  <img src="assets/images/error-icon.png" alt="error image">
  <h1>We can't load this page now.</h1>
  <p class="error-text">Please try again later. Thanks for your patience as we work this out.</p>
</div>

<!-- error message when zip code is invalid or only State or city is entered -->
<div class="error_wrapper text-center" *ngIf="invalidZip || invalidStateOrCity">
  <div class="form-message card attention">
    <h3 class="error-red">
            <span class="form-message-icon-attention mr-3">
                <i class="icon icon-attention-alt"></i>
            </span>
      <span class="form-message-contents">
                <span class="form-message-text" [innerHTML]="errorMsgText"></span>
            </span>
    </h3>
  </div>
</div>

<div class="error_wrapper">
  <!-- display when no result or no result in radius or invalid search -->
  <h3 *ngIf="noResults || noResultsInRadius || invalidSearch" class="text-center">
    <i class="icon icon-attention-alt"></i> We can't find any {{ errorMsgText }} in this area.
  </h3>
  <!-- display when no result found -->
  <p *ngIf="noResults" class="text-center error-text">Please check your spelling or increase your search radius.</p>
  <!-- display when invalid serach or no result in radius -->
  <p *ngIf="invalidSearch || noResultsInRadius" class="text-center error-text">Please check your spelling or pick
    another city, state, or ZIP.</p>
</div>

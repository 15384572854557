import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class GeoLocationService {
  coordinates: any;
  geoLocationCoordinates: GeolocationCoordinates = {
    latitude: 41.7696634,
    longitude: -72.6870864,
    accuracy: 1,
    altitude: 1,
    altitudeAccuracy: 12,
    heading: 123,
    speed: 345,
  };

  getLocation(): Observable<GeolocationCoordinates> {
    if (window.navigator && window.navigator.geolocation) {
      return new Observable<GeolocationCoordinates>((observer) => {
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            observer.next(position.coords);
            observer.complete();
          },
          (err) => {
            observer.next(this.geoLocationCoordinates);
            observer.complete();
          },
          // cached location up to 1 hour and wait maximum of 5 secs
          { maximumAge: 3600000, timeout: 5000 }
        );
      });
    } else {
      return of(this.geoLocationCoordinates);
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Location } from '../services/location.service';

@Pipe({
  name: 'directionsLink',
})
export class DirectionsLinkPipe implements PipeTransform {
  private baseUrl = 'https://www.google.com/maps/dir/?api=1';

  transform(value: Location): string {
    if (!value || !value.address || !value.name) {
      return '';
    }
    const destinationParam = encodeURIComponent(`${value.name},${value.address.city},${value.address.state},${value.address.zip}`);
    return this.baseUrl + '&destination=' + destinationParam;
  }
}

export const appConstants = {
  clientId: '1162',
  apiUrlPrefix: 'v1/cl/locsvcs/',
  agencyRealm: 'agency',
  repairShopRealm: 'repair-shop',

  agencyURL: 'agency',
  agencyTitle: 'Find an Agency',
  repairShopURL: 'repair-shop',
  repairShopTitle: 'Find an Auto Repair Shop',

  testHarness: 'HARNESS',
  zipCodePattern: /^\d+$/,
  addressPattern: /^[A-Za-z\s.'-]{1,50}$/,
  debounceTime: 2000,
  timeout: 1000,
};

import { Injectable } from '@angular/core';
import { RecaptchaService } from './recaptcha.service';
import { Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { appConstants } from '../config/app-constants';
import { EnvConfig } from '../../config/env-config';

export interface LocationRequest {
  realm: string;
  lob?: string;
  location?: string;
  searchRadius: number;
  coordinate?: { latitude: number; longitude: number };
}

export interface Location {
  name: string;
  address: { street: string; city: string; county?: string; state: string; zip: string };
  coordinates: { latitude: number; longitude: number };
  distance: number;
  lob?: string;
  selected?: boolean;
  properties: {
    outOfNetwork?: string;
    phoneNumber?: string;
    url?: string;
    aarpInd?: string;
    htCode?: string;
    shopNumber?: string;
  };
}

@Injectable()
export class LocationService {
  constructor(private recaptchaService: RecaptchaService, private http: HttpClient) {}

  public getLocationList(request: LocationRequest): Observable<any> {
    let params = new HttpParams().set('client_id', appConstants.clientId);
    if (EnvConfig.TARGET_ENV) {
      params = params.set('target_env', EnvConfig.TARGET_ENV);
    }

    return this.recaptchaService.getJwtToken().pipe(
      take(1),
      mergeMap((token) => {
        return this.http.post<Location[]>(EnvConfig.API_BASE + appConstants.apiUrlPrefix + 'locations', request, {
          params: params,
          headers: new HttpHeaders().set('Authorization', `${token.token_type} ${token.access_token}`),
        });
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { EnvConfig } from '../../config/env-config';
import { appConstants } from '../config/app-constants';
import { RecaptchaComponent } from 'ng-recaptcha';

export class Token {
  access_token: string;
  token_type: string;
  expires_in: string;
}

@Injectable()
export class RecaptchaService {
  private tokenSubject: Subject<string> = new Subject<string>();
  private cachedToken: Token;
  private tokenSet: number;
  private inProgress: Observable<Token> | null;
  private recaptchaComponent: RecaptchaComponent;
  private recaptchaToken: string;

  constructor(private http: HttpClient) {}

  public setRecaptchaToken(token: string): void {
    this.recaptchaToken = token;
    this.tokenSubject.next(token);
  }

  public setRecaptchaComponent(component: RecaptchaComponent): void {
    this.recaptchaComponent = component;
  }

  private getRecaptchaToken(): Observable<string> {
    if (this.recaptchaToken) {
      return of(this.recaptchaToken);
    }

    // ask for a new recaptcha token
    if (this.recaptchaComponent) {
      this.recaptchaComponent.execute();
    }

    return this.tokenSubject.asObservable();
  }

  public getJwtToken(): Observable<Token> {
    if (this.cachedToken && Date.now() < this.tokenSet + Number(this.cachedToken.expires_in) * 1000) {
      return of(this.cachedToken);
    }

    if (this.inProgress) {
      return this.inProgress;
    }

    let params = new HttpParams().set('responsetype', 'token').set('client_id', appConstants.clientId);
    if (EnvConfig.TARGET_ENV) {
      params = params.set('target_env', EnvConfig.TARGET_ENV);
    }

    this.inProgress = this.getRecaptchaToken().pipe(
      take(1),
      mergeMap((token) => {
        let headers = new HttpHeaders();
        if (appConstants.testHarness === token) {
          params = params.set('scope', 'automation');
        } else {
          params = params.set('scope', 'recaptcha');
          headers = headers.set('recaptcha', token);
        }

        return this.http
          .get<Token>(EnvConfig.API_BASE + 'authorize', {
            headers: headers,
            params: params,
            withCredentials: true,
          })
          .pipe(
            map((t) => {
              this.cachedToken = t;
              this.tokenSet = Date.now();
              this.inProgress = null;
              return t;
            })
          );
      })
    );

    return this.inProgress;
  }
}

import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { GeoLocationService } from '../services/geo-location.service';
import { Subscription } from 'rxjs';
import { appConstants } from '../config/app-constants';
import { Location, LocationRequest, LocationService } from '../services/location.service';
import { ActivatedRoute } from '@angular/router';
import { IframeUtils } from '../utilities/iframe.utils';
import { AnalyticsService } from '../analytics/analytics.service';

@Component({
  selector: 'app-repair-shop',
  templateUrl: './repair-shop.component.html',
  styleUrls: ['./repair-shop.component.scss'],
})
export class RepairShopComponent implements OnInit, OnDestroy {
  tilesDisplayed = 0;
  limitPerPage = 10;
  numberOfPages = 0;
  locationList: Location[] = [];
  paginatedLocationList: Location[] = [];
  noResults = false;
  noResultsInRadius = false;
  invalidSearch = false;
  geolocationPositionError: string;
  subscription: Subscription;
  radius = 25;
  displayRadius = 25;
  location = '';
  coordinates: { latitude: number; longitude: number };
  showSelectShop = false;
  showSpinner = false;
  isServicesUnavailable = false;
  invalidZip = false;
  invalidStateOrCity = false;
  errorMsgText = '';

  constructor(private locationService: LocationService, private geoLocationService: GeoLocationService, private activatedRoute: ActivatedRoute, public analytics: AnalyticsService) {}

  ngOnInit() {
    this.showSelectShop = this.activatedRoute.snapshot.queryParams['selectShop'] === 'true';
    const getLocation = this.geoLocationService.getLocation();
    this.subscription = getLocation.subscribe({
      next: (coordinates) => {
        this.coordinates = { latitude: coordinates.latitude, longitude: coordinates.longitude };
        this.setLocationList();
      },
      error: (error) => {
        this.geolocationPositionError = error;
        if (this.geolocationPositionError) {
          this.coordinates = {
            latitude: this.geoLocationService.geoLocationCoordinates.latitude,
            longitude: this.geoLocationService.geoLocationCoordinates.longitude,
          };
          this.setLocationList();
        }
      },
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  changePages(event: any) {
    const startPage = (event.detail - 1) * this.limitPerPage;
    let endPage = (event.detail - 1) * this.limitPerPage + this.limitPerPage;
    if (this.locationList.length < endPage) {
      endPage = this.locationList.length;
    }
    this.paginatedLocationList = this.locationList.slice(startPage, endPage);
    IframeUtils.resizeIframe(true);
  }

  onSearchLocation(): void {
    this.showSpinner = true;
    this.locationList = [];
    this.noResults = false;
    this.invalidSearch = false;
    this.noResultsInRadius = false;
    this.invalidStateOrCity = false;
    this.invalidZip = false;
    this.displayRadius = this.radius;
    this.setLocationList();
  }

  setLocationList() {
    this.showSpinner = true;
    const request: LocationRequest = {
      realm: appConstants.repairShopRealm,
      searchRadius: this.radius,
    };
    if (this.location) {
      request.location = this.location;
      const initialWord = this.location.split(' ')[0];
      if (appConstants.zipCodePattern.test(initialWord) && initialWord.length !== 5) {
        this.errorMsgText = 'Please enter a valid, 5-digit ZIP code.';
        this.isServicesUnavailable = false;
        this.invalidZip = true;
        this.showSpinner = false;
        setTimeout(() => {
          this.analytics.trackInputError('Repair Shop Location', this.errorMsgText);
        }, appConstants.timeout);
        return;
      } else if (!appConstants.zipCodePattern.test(initialWord) && (this.location.trim().indexOf(' ') === -1 || !appConstants.addressPattern.test(this.location))) {
        this.errorMsgText = 'Please enter a valid city and state.';
        this.isServicesUnavailable = false;
        this.invalidStateOrCity = true;
        this.showSpinner = false;
        setTimeout(() => {
          this.analytics.trackInputError('Repair Shop Location', this.errorMsgText);
        }, appConstants.timeout);
        return;
      }
    } else if (this.coordinates) {
      request.coordinate = {
        latitude: this.coordinates.latitude,
        longitude: this.coordinates.longitude,
      };
    }
    this.locationService.getLocationList(request).subscribe({
      next: (locationList) => {
        this.showSpinner = false;
        if (locationList.locations && locationList.locations.length) {
          this.locationList = locationList.locations;
          this.paginatedLocationList = this.locationList.slice(0, this.limitPerPage);
          this.numberOfPages = Math.ceil(this.locationList.length / this.limitPerPage);
          this.tilesDisplayed = this.numberOfPages >= 3 ? 3 - 1 : this.numberOfPages - 1;
        } else if (this.radius > 100) {
          this.noResultsInRadius = true;
          this.errorMsgText = 'repair shops';
          this.analytics.trackMessageAppearance('Warning Message', 'Repair Shop Invalid Search', 'Not find any repair shops in this area, pick another city, state, or ZIP');
        } else {
          this.noResults = true;
          this.errorMsgText = 'repair shops';
          this.analytics.trackMessageAppearance('Warning Message', 'Repair Shop Invalid Search', 'Not find any repair shops in this area, increase your search radius');
        }

        IframeUtils.resizeIframe();
      },
      error: (error) => {
        console.error(error, error);
        this.showSpinner = false;
        this.invalidSearch = true;
        this.errorMsgText = 'repair shops';
        if (error.status === 0 || error.status === 500) {
          this.invalidSearch = false;
          this.isServicesUnavailable = true;
          this.analytics.trackMessageAppearance('Error Message', 'Repair Shop System Down', 'Not able to load repair shop locator page now');
        }
        if (this.invalidSearch) {
          this.analytics.trackMessageAppearance('Warning Message', 'Repair Shop Invalid Search', 'Not find any repair shops in this area, pick another city, state, or ZIP');
        }
        IframeUtils.resizeIframe();
      },
    });
  }

  locationSelected(location: Location): void {
    this.locationList
      .filter((x) => x.selected)
      .forEach((x) => {
        IframeUtils.emitShopLocation(x, true);
        x.selected = false;
      });
    location.selected = true;
    IframeUtils.emitShopLocation(location);
  }

  submitLocationDetails(event: any) {
    event.preventDefault();
    this.onSearchLocation();
  }

  handleKeyUp(event: any) {
    if (event.keyCode === 13) {
      this.analytics.trackFormSubmit('Repair Shop Location');
      this.submitLocationDetails(event);
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    IframeUtils.resizeIframe();
  }

  trackLink(linkName: string, location: Location) {
    const linkText = `${linkName} - ${location.name}`;
    const eventValue = `${location.address.state === 'MA' && location.properties.outOfNetwork === 'Y' ? 'Out-of-Network' : 'In-Network'}`;
    this.analytics.trackLink(linkText, eventValue);
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { appConstants } from '../config/app-constants';
import { IframeUtils } from '../utilities/iframe.utils';

export interface TealiumEventData {
  event_parent: string;
  event_type: string;
  event_value?: string;
  event_id?: string;
  da_track?: string;
}

@Injectable()
export class AnalyticsService {
  inputValueChanged: Subject<TealiumEventData> = new Subject<TealiumEventData>();

  constructor() {
    // whenever the inputValueChanged subject is called we will wait a very short time
    // to see if another input comes in, and then fire the event
    this.inputValueChanged.pipe(debounceTime(appConstants.debounceTime)).subscribe((val) => {
      this.customTealiumEvent(val);
    });
  }

  /**
   * fire tealium event with the specified data
   * @param data
   */
  customTealiumEvent(data: TealiumEventData): void {
    data.da_track = 'true';
    console.log(`Firing analytics event with data: ${JSON.stringify(data)}`);
    setTimeout(() => {
      IframeUtils.emitAnalyticsEvent(data);
    }, appConstants.timeout);
  }

  /**
   * tracking event for huk2 input field, takes input label as input
   * @param inputLabel
   */
  trackHuk2InputField(inputLabel: string, $event: any): void {
    if (!$event?.target) {
      console.log('Incorrect target provided for analytics event');
      return;
    }

    if ($event?.target?.value) {
      const analyticsData: TealiumEventData = {
        event_parent: 'Page Tag',
        event_type: 'Input Field Click - (Text)',
        event_id: inputLabel,
      };
      this.inputValueChanged.next(analyticsData);
    }
  }

  /**
   * tracking event for huk2 select, takes select label as input
   * @param selectLabel
   * @param $event
   */
  trackHuk2Select(selectLabel: string, $event: any): void {
    if (!$event?.detail) {
      console.log('Incorrect Detail provided for analytics event');
      return;
    }
    const analyticsData: TealiumEventData = {
      event_parent: 'Page Tag',
      event_type: 'Select Click',
      event_id: selectLabel,
      event_value: $event.detail,
    };
    this.customTealiumEvent(analyticsData);
  }

  /**
   * tracking for huk2 button, takes button text and event as inputs
   * @param buttonText
   * @param $event
   */
  trackHuk2Button(buttonText: string, $event: any): void {
    if (!$event?.detail) {
      console.log('Incorrect Detail provided for analytics event');
      return;
    }
    const analyticsData = {
      event_parent: 'Page Tag',
      event_type: 'Button Click',
      event_id: buttonText,
      event_value: $event.detail,
    };
    this.customTealiumEvent(analyticsData);
  }

  /**
   * tracking for huk2 paginator, takes pagination label and event as input
   * @param paginationLabel
   * @param $event
   */
  trackHuk2Paginator(paginationLabel: string, $event: any): void {
    if (!$event?.detail) {
      console.log('Incorrect Detail provided for analytics event');
      return;
    }
    const analyticsData = {
      event_parent: 'Page Tag',
      event_type: 'Pagination Link Click',
      event_id: paginationLabel,
      event_value: String($event.detail),
    };
    this.customTealiumEvent(analyticsData);
  }

  /**
   * tracking for form submit, takes inputLabel as input
   * @param inputLabel
   */
  trackFormSubmit(inputLabel: string): void {
    const analyticsData = {
      event_parent: 'Page Tag',
      event_type: 'Input Field Click - (Submit)',
      event_id: inputLabel,
      event_value: 'Submit',
    };
    this.customTealiumEvent(analyticsData);
  }

  /**
   * tracking for link click, takes link text and event value as input
   * @param linkText
   * @Param eventValue
   */
  trackLink(linkText: string, eventValue: string): void {
    const analyticsData: TealiumEventData = {
      event_parent: 'Page Tag',
      event_type: 'Link Click',
      event_id: linkText,
      event_value: eventValue,
    };
    this.customTealiumEvent(analyticsData);
  }

  /**
   * tracking for input error message shown, takes input label and error message as inputs
   * @param inputLabel
   * @param errorMessage
   */
  trackInputError(inputLabel: string, errorMessage: string): void {
    const analyticsData: TealiumEventData = {
      event_parent: 'Page Tag',
      event_type: 'Form Validation Error',
      event_id: inputLabel,
      event_value: errorMessage,
    };
    this.customTealiumEvent(analyticsData);
  }

  /**
   * tracking for message shown, takes messageType, messageId and message as inputs
   * @param messageType
   * @param messageId
   * @param message
   */
  trackMessageAppearance(messageType: 'Information Message' | 'Warning Message' | 'Error Message', messageId: string, message: string): void {
    const analyticsData: TealiumEventData = {
      event_parent: 'Page Tag',
      event_type: messageType,
      event_id: messageId,
      event_value: message,
    };
    this.customTealiumEvent(analyticsData);
  }
}
